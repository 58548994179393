@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

.sign-in-container h1 {
  font-weight: bold;
  margin: 0;
  margin-bottom: 36px;
}

.sign-in-container h2 {
  text-align: center;
}

.sign-in-container p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}
.h {
  padding-bottom: 50px;
}

.sign-in-container span {
  font-size: 12px;
}

.sign-in-container a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.sign-in-container button {
  border-radius: 20px;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.sign-in-container button:active {
  transform: scale(0.95);
}

.sign-in-container button:focus {
  outline: none;
}

.sign-in-container button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

.sign-in-container form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.sign-in-container input {
  outline: none;
  border-radius: 16px;
  background-color: #eee;
  border: none;
  padding: 16px 15px;
  margin: 8px 0;
  width: 60%;
}

.login-container {
  display: flex;
  background-color: #fff;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  max-width: 100%;
  min-height: 100vh;
}

.sign-in-container {
  width: 50%;
  z-index: 2;
}

.overlay-container {
  position: relative;
  width: 50%;
  display: flex;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
  background: #0e227d;
  background: -webkit-linear-gradient(to right, #0e227d, #1a36b1);
  background: linear-gradient(to right, #0e227d, #1a36b1);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
}
.overlay-right.logo img {
  width: 100px;
  position: absolute;
  top: 8%;
  left: 8%;
}
.overlay-right.side-img img {
  left: 16.66%;
  width: 500px;
  position: absolute;
  top: 33%;
}
.form-img {
  display: none;
  background: black;
  border: 1px solid black;
  border-radius: 50%;
  margin-bottom: 24px;
  width: 100px;
  height: 100px;
}

@media only screen and (max-width: 768px) {
  .overlay-container {
    display: none;
  }
  .form-img {
    display: flex;
  }
  .sign-in-container {
    width: 100%;
  }
  .sign-in-container input {
    width: 100%;
  }
}
