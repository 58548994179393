@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6ffff;
}
.space_between {
  justify-content: space-between;
}
.d_flex {
  display: flex;
  align-items: center;
}

.flex_col {
  display: flex;
  flex-direction: column;
}
.gap-4 {
  gap: 4px;
}
.d_none {
  display: none;
}
.d_start {
  display: flex;
  align-items: flex-start;
}

.gap-40 {
  gap: 40px;
}
.gap-32 {
  gap: 32px;
}

.gap-16 {
  gap: 16px;
}
.gap-24 {
  gap: 24px;
}
.gap-8 {
  gap: 8px;
}
