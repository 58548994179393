.activity-container {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  background: #f4f5f7;
}
.activity-card-box {
  gap: 40px;
  display: flex;
}
.activity-card {
  height: 550px;
  overflow: hidden;
}
.activity-card-box .activity-card {
  flex: 1;
  background-color: #ffffff;
}
.activity-card .card-header {
  padding: 16px;
  border-bottom: 1px solid #ccc;
}
.activity-card .card-header h3 {
  color: #5c5962;
  font-weight: 550;
}
.activity-card .card-body {
  overflow: hidden;
  height: 100%;
  padding: 16px 32px;
}
.activity-card .card-body .card-item {
  padding: 4px 24px;
  margin: 12px 0;
  position: relative;
}
.activity-card .card-body .card-item::before {
  content: "";
  position: absolute;
  left: 0;
  width: 4px;
  border-radius: 8px;
  height: 100%;
  cursor: pointer;
  background: purple;
}
/* .activity-card .card-body .card-item .checkbox {
  border-radius: 20px;
  width: 20px;
  height: 20px;
} */
.activity-card .card-body .card-item input[type="checkbox"] {
  display: none;
}
.activity-card .card-body .card-item .checkbox label {
  display: flex;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

/* Create the checkbox design */
.checkbox label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: #eeeff4;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
}

/* 
.checkbox input[type="checkbox"]:checked + label:before {
  background-color: green;
} */

.activity-card .card-body .card-item .title span {
  font-size: 12px;
  color: #6d7071;
  cursor: pointer;
}
.activity-card .card-body .card-item .title .title-span {
  font-size: 14px;
  color: #6d7071;
  font-weight: 500;
  cursor: pointer;
}
.date-time {
  width: 100%;
  max-width: 56px;
}
.log-item {
  font-size: 14px;
  padding: 8px 0px;
  color: #898bb1;
}
.log-item .info {
  padding-left: 12px;
  position: relative;
}
.log-item .info::before {
  content: "";
  width: 12px;
  height: 12px;
  border: 2px solid var(--before-color);
  border-radius: 50%;
  position: absolute;
  left: -6%;
  top: 4%;
}
.log-item .info::after {
  content: "";
  width: 2px;
  height: calc(100% - 12px);
  background-color: grey;
  position: absolute;
  left: calc(-4% + 2px);
  top: calc(4% + 22px);
}
.log-items {
  height: 100%;
  overflow-y: scroll;
}
.activity-log::-webkit-scrollbar {
  width: 0;
}
.log-items::-webkit-scrollbar {
  width: 0;
}
.blue-circle {
  --before-color: blue;
}
.red-circle {
  --before-color: red;
}
.audit-items {
  padding-left: 24px;
}
.audit-item {
  padding-left: 12px;
  position: relative;
  padding-bottom: 24px;
}
.audit-item::before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: var(--before-color);
  border-radius: 50%;
  position: absolute;
  left: -6%;
  top: 8%;
}
.audit-item::after {
  content: "";
  width: 1px;
  height: calc(100% - 8px);
  background-color: grey;
  position: absolute;
  left: calc(-6% + 3px);
  top: calc(8% + 8px);
}
.activity-card .card-body .audit-item .title span {
  font-size: 13px;
  color: #6d7071;
  cursor: pointer;
}
.activity-card .card-body .audit-item .title .title-span {
  font-size: 15px;
  color: #6d7071;
  font-weight: 500;
  cursor: pointer;
}

@media (min-width: 991px) {
  .activity-container {
    padding-left: 116px;
  }
}
@media (max-width: 768px) {
  .activity-card-box {
    flex-direction: column;
  }
}
