.data_info {
  padding-left: 24px;
  padding-top: 24px;
  padding-right: 24px;
}

.tab_content {
  display: none;
}

.tab_content.active {
  display: block;
}
.tab_button {
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
}
.tab_button button {
  flex: 1;
  padding: 12px;
  height: 56px;
  background-color: #ffffff;
  border: none;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

.tab_button button.active {
  background-color: #e6fbff;
}

.single_data:not(:last-child) {
  margin-bottom: 24px;
}
.single_data {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  padding: 16px;
}
.single_data h3 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}
.single_data .tab_title span,
.range_input span {
  font-size: 12px;
  color: #757575;
  line-height: 16px;
}

.tab_title .title_box {
  max-width: 160px;
  width: 100%;
}

.data_check input {
  display: none;
}
.data_check label {
  position: relative;
  cursor: pointer;
}
.data_check label::before {
  width: 40px;
  content: "";
  height: 20px;
  border: 1px solid #4a90ff;
  border-radius: 30px;
  display: block;
}
.data_check label::after {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  background-color: #4a90ff;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  transition: 0.5s;
}
.data_check input:checked ~ label:after {
  transform: translateX(20px);
}

.tab_status ul li,
.tab_delete button {
  width: 36px;
  height: 36px;
  display: grid;
  place-items: center;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  cursor: pointer;
  color: #4a90ff;
}
.tab_range {
  max-width: 540px;
  width: 100%;
}

.tab_range .range_progress span {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: #4a90ff;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}
.tab_delete button {
  border: none;
  cursor: pointer;
  background-color: #ffffff;
}

.range_input input,
.range_input {
  width: 100%;
}
.flex_wrap {
  flex-wrap: wrap;
}
.tab_title {
  width: 100%;
}
@media (min-width: 992px) {
  .data_info {
    max-width: calc(100% - 148px);
    width: 100%;
    padding-left: 124px;
  }
  .tab_title {
    max-width: 310px;
  }
}
@media (max-width: 992px) {
  .single_data {
    flex-direction: column;
    align-items: start;
    gap: 24px;
  }
  .tab_title {
    align-items: start;
  }
}

@media (max-width: 580px) {
  .tab_button button {
    height: 56px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }
  .data_info {
    padding-left: 12px;
    padding-right: 12px;
  }
  .tab_status ul {
    gap: 16px;
  }
}
