:root {
  --theme-color: #60d4e3;
  --home-theme: #00cdfb;
  --theme-one: #fa8fc1;
  --theme-two: #99754566;
  --primary-color: #231d70;
  --section-bg: #231d70;
  --section-bg-two: #fff9f5;
  --button-color: #0d4bbc;
  --secoundary-color: #1e19cf;
  --secoundary-one: #2f16;
  --white-color: #fff;
  --black-color: #090303;
  --ratting-color: #ffd247;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}
a {
  text-decoration: none;
  margin: 0;
  color: black;
  font-family: "Montserrat", sans-serif;
}
button {
  font-family: "Montserrat", sans-serif;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--box-bg);
  border-radius: 5px;
}

/*----Menu Header Start-----*/
.header-section {
  width: 100%;
  background: #ffffff;
  z-index: 99999;
  position: sticky;
  top: 0;
  left: 0;
  height: 100px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
}

.home__header.menu-fixed {
  background: rgb(67, 58, 193);
  background: linear-gradient(
    90deg,
    rgb(0, 215, 255) 0%,
    rgb(0, 215, 255) 30%,
    rgb(67, 58, 193) 100%
  );
}

.sharp__header.menu-fixed {
  background: rgb(67, 58, 193);
  background: linear-gradient(
    90deg,
    rgb(67, 58, 193) 0%,
    rgb(11, 4, 131) 30%,
    rgb(0, 215, 255) 100%
  );
}

.menu-fixed {
  background: #ffffff;
  position: sticky;
  top: 0;

  width: 100%;
  z-index: 1000;
  transition: all 0.9s;
  background: linear-gradient(
    90deg,
    rgb(0, 215, 255) 0%,
    rgb(0, 215, 255) 30%,
    rgb(67, 58, 193) 100%
  );
  border-bottom: unset;
}

.header-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  transition: all 0.9s;
  padding: 10px 32px 10px 14px;
}
.header-wrapper .logo-menu {
  max-width: 75px;
  height: auto;
}
.header-wrapper .logo-menu-content {
  font-size: 20px;
  max-width: max-content;
  left: 10%;
  top: 36%;
  position: absolute;
  height: auto;
}

.header-wrapper .logo-menu img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.header-wrapper .cmn--btn {
  padding: 8px 25px 9px;
}
.header-wrapper .main-menu {
  position: relative;
  display: flex;
  align-items: center;
}
.header-wrapper .main-menu li {
  transition: all 0.5s;
  position: relative;
}
.header-wrapper .main-menu li:not(:last-child) {
  margin-right: 40px;
}
.header-wrapper .main-menu li a {
  color: black;
  font-weight: 500;
  font-size: 14px;
  transition: all 0.3s;
}
.header-wrapper .main-menu li a i {
  margin-left: 2px;
  font-size: 14px !important;
}
.header-wrapper .main-menu li .sub-menu {
  margin: 0 10px 10px 10px;
  display: none;
  transform: translateY(15px);
}
@media (min-width: 992px) {
  .header-wrapper .main-menu li .sub-menu {
    position: absolute;
    top: 100%;
    left: -16px;
    z-index: 999;
    width: 250px;
    transition: all 0.8s;
    box-shadow: -1px 7px 19px -14px rgb(66, 106, 184);
    background-color: rgb(245, 244, 255);
    border-radius: 10px;
    display: block !important;
    opacity: 0;
    visibility: hidden;
  }
}
.header-wrapper .main-menu li .sub-menu li {
  border: unset;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  margin-right: 0;
  margin-bottom: 5px;
  transition: all 0.4s !important;
}
.header-wrapper .main-menu li .sub-menu li a {
  color: black;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  gap: 8px;
  padding: 14px 20px;
  border-radius: 8px;
}
.header-wrapper .main-menu li .sub-menu li a i {
  font-size: 14px !important;
}
.header-wrapper .main-menu li .sub-menu li:hover a {
  margin-left: 10px;
}
.header-wrapper .main-menu li .cmn--btn {
  color: var(--white-color);
  display: none;
}
.header-wrapper .main-menu li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
}

/*----Menu Header Start-----*/

/*--Button Area Start--*/
/*--Button Area Start--*/
.cmn--btn {
  border-radius: 10px;
  padding: 13px 30px 14px;
  font-size: 18px;
  font-weight: 500;
  color: var(--white-color);
  text-transform: capitalize;
  background: linear-gradient(
    90deg,
    rgb(0, 215, 255) 0%,
    rgb(0, 215, 255) 30%,
    rgb(67, 58, 193) 100%
  );
  display: inline-block;
  transform: skew(-21deg);
}
.cmn--btn span {
  display: inline-block;
  transform: skew(21deg);
}
.cmn--btn:hover {
  color: var(--white-color);
  background: linear-gradient(#f57171, #7c84f4);
}

.border__trans {
  background: transparent;
  border: 1px solid var(--theme-color);
  padding: 9px 20px 9px;
  position: relative;
}
.border__trans::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0px;
  height: 0px;
  background-color: #00cdfb;
  content: "";
  border-radius: 10px;
  transition: all 0.4s;
}
.border__trans:hover {
  background: unset;
}
.border__trans:hover::before {
  width: 100%;
  height: 100%;
}

.main-menu .main_ul {
  display: flex;
}
/*--Button Area End--*/
/*--Button Area End--*/

@media screen and (max-width: 991px) {
  .header-section {
    background: rgb(67, 58, 193);
    border-bottom: unset;
    background: linear-gradient(
      90deg,
      rgb(0, 215, 255) 0%,
      rgb(0, 215, 255) 30%,
      rgb(67, 58, 193) 100%
    );
  }
  .main-menu {
    position: fixed !important;
    top: 100px;
    display: inline !important;
    left: 0;
    z-index: 999;
    padding: 16px;
    transition: all 2s !important;
    background: #ffffff;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    min-height: calc(100dvh - 100px);
    max-width: 280px;
    width: 100%;
  }
  .main-menu:not(.active) {
    display: none !important;
    transition: all 2s;
  }
  .main-menu li {
    border-bottom: none;
    border-radius: 10px;
  }
  .header-wrapper .main-menu li .sub-menu li {
    box-shadow: none;
  }
  .main-menu li:not(:last-child) {
    margin-right: 0 !important;
    margin-bottom: 15px;
  }
  .main-menu li a {
    display: block;
    padding: 12px;
    font-size: 17px;
  }
  .main-menu li .sub-menu {
    transform: translateY(0px) !important;
    margin: 0px 10px 15px 20px !important;
  }
  .main-menu li .sub-menu li {
    margin-bottom: 10px;
  }
  .main-menu li .sub-menu li a {
    padding: 12px !important;
  }
  .main-menu li .sub-menu li .sub-two {
    position: static !important;
    width: 100% !important;
    padding: 15px 10px 20px 30px;
  }
  .main-menu li .sub-menu li .sub-two li a {
    padding: 10px 15px !important;
    display: block;
  }
  .main-menu .btn--items {
    box-shadow: none;
    border: unset;
  }
  .main-menu .btn--items .cmn--btn {
    width: 140px;
    text-align: center;
    display: block !important;
    background: rgb(67, 58, 193);
    background: linear-gradient(
      90deg,
      rgb(67, 58, 193) 0%,
      rgb(11, 4, 131) 30%,
      rgb(0, 215, 255) 100%
    );
  }
  .main-menu .active a {
    color: var(--white-color);
  }
  .main-menu li .sub-menu.active {
    display: block;
  }
  .main-menu .main_ul {
    height: calc(100dvh - 140px);
    overflow-y: scroll;
    padding-bottom: 8px;
    flex-direction: column;
  }
  .header-bar {
    position: relative;
    width: 40px;
    height: 20px;
  }
  .header-bar span {
    position: absolute;
    width: 100%;
    height: 2px;
    display: inline-block;
    transition: all 0.3s;
    left: 0;
    background: var(--theme-color);
  }
  .header-bar span:first-child {
    top: 0;
    background: var(--theme-color);
  }
  .header-bar span:nth-child(2) {
    top: 44%;
    width: 30px;
    background: var(--theme-color);
  }
  .header-bar span:last-child {
    bottom: 0;
    width: 25px;
    background: var(--theme-color);
  }
  .header-bar.active span:first-child {
    transform: rotate(45deg) translate(3px, 9px);
  }
  .header-bar.active span:nth-child(2) {
    opacity: 0;
  }
  .header-bar.active span:last-child {
    transform: rotate(-45deg) translate(3px, -9px);
    width: 40px;
  }
  .header-bar:hover {
    cursor: pointer;
  }
  .header-wrapper .cmn--btn {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .header-wrapper .logo-menu-content {
    left: 25%;
  }
}
