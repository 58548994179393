.main-container {
  /* font-family: "Montserrat", sans-serif; */
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  background: #f4f5f7;
}
.intro-container {
  /* font-family: "Montserrat", sans-serif; */
  /* padding-left: 116px;
    padding-right: 16px;
    padding-top: 24px;
    padding-bottom: 24px; */

  background: #ffffff;
}
.left-container {
  padding: 16px;
  position: relative;
  width: 64%;
  /* border-right: 1px solid #ccc; */
}

.left-container .upper-container {
  padding: 24px 0px;
}
.left-container .upper-container .image-container {
  max-width: 160px;
  width: 100%;
}
.left-container .upper-container .content {
  display: flex;
  padding-top: 8px;
}
.left-container .upper-container .content .title span:nth-child(1) {
  font-size: 20px;
  font-weight: 600;
}
.left-container .upper-container .content .title span:nth-child(1):hover {
  color: #00d7ff;
  cursor: pointer;
  text-decoration: underline;
}
.left-container .upper-container .content .title span:nth-child(2) i {
  font-size: 18px;
  font-weight: 600;
  color: #00d7ff;
  cursor: pointer;
}
.left-container .upper-container .content .title span:nth-child(3) {
  font-size: 11px;
  font-weight: 700;
  background-color: #f1faff;
  color: #00d7ff;
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid #00d7ff;
}
.left-container .upper-container .content .title span:nth-child(3):hover {
  background-color: #00d7ff;
  color: #ffffff;
  cursor: pointer;
}
.upper-container .content .cards {
  flex-wrap: wrap;
}
.upper-container .content .cards .card {
  display: flex;
  border-radius: 4px;
  align-items: flex-start;
  padding: 12px 24px 12px 12px;
  gap: 8px;
  width: 95px;
  flex-direction: column;
  border: 1px dashed #ccc;
}
.upper-container .content .cards .card span {
  font-size: 14px;
  color: #c1b9c3;
}
.upper-container .content .cards .card span i {
  font-size: 14px;
}
.lower-container {
  padding: 36px 0;
}
.lower-container ul {
  flex-wrap: wrap;
}
.lower-container ul li a {
  color: #a1a5b7;
  font-weight: 600;
  position: relative;
  transition: color 0.2s ease;
  margin-bottom: 24px;
}
.lower-container ul li a::after {
  content: "";
  position: absolute;
  top: 36px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #00d7ff;
  transition: width 0.3s ease;
}
.lower-container ul li a.active::after {
  content: "";
  position: absolute;
  top: 36px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #00d7ff;
  transition: width 0.3s ease;
}
.lower-container ul li a:hover::after {
  width: 100%;
}
.lower-container ul li a.active {
  color: #00d7ff;
}
.right-container {
  width: 36%;
}
.right-container .heading {
  padding: 0px 24px;
}
.right-container .heading h2 {
  color: #575962;
}
.right-container .heading span {
  color: #b199b7;
  font-size: 14px;
}
.right-container .clock img {
  max-width: 200px;
}
.right-container .clock {
  max-width: 100%;
  justify-content: center;
}
.right-container .clock span {
  font-size: 32px;
  font-weight: 600;
}
.right-container .report {
  max-width: 100%;
  justify-content: center;
}
.right-container .report a {
  color: #ffffff;
  background-color: #00d7ff;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
}

/* Media Query */

@media (min-width: 991px) {
  .main-container {
    /* font-family: "Montserrat", sans-serif; */
    padding-left: 116px;
  }
  .left-container::after {
    display: block;
    content: "";
    width: 1px;
    top: 0;
    position: absolute;
    right: 0;
    height: 100%;
    background-color: #ccc;
  }
}
@media (max-width: 991px) {
  .intro-container {
    padding: 16px;
    flex-direction: column;
  }
  .left-container,
  .right-container {
    width: 100%;
  }
  .right-container {
    flex-direction: row;
    gap: 60px;
    align-items: center;
  }
  .left-container::before {
    display: block;
    content: "";
    width: calc(100% - 32px);
    position: absolute;
    bottom: 16px;
    height: 1px;
    background-color: #ccc;
  }
}
@media (max-width: 767px) {
  .intro-container {
    padding: 16px;
    flex-direction: column;
  }
  .left-container,
  .right-container {
    width: 100%;
  }
  .upper-container {
    flex-direction: column;
  }
  .lower-container ul {
    gap: 24px;
  }
  .lower-container ul li {
    padding: 16px 0px;
  }
  .right-container {
    flex-direction: column;
    gap: 24px;
  }
  .left-container::before {
    display: block;
    content: "";
    width: calc(100% - 32px);
    position: absolute;
    bottom: 16px;
    height: 1px;
    background-color: #ccc;
  }
}
