.traffic-container {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  background: #f4f5f7;
}
.traffic-card-box {
  gap: 0px;
  display: flex;
}
.traffic-card-box .traffic-card {
  flex: 1;
  background-color: #ffffff;
  border-right: 1px solid #ebedf2;
}

.traffic-card .card-body {
  padding: 24px 32px;
  padding-top: 0px;
}

.traffic-card .card-body .card-item {
  padding: 24px 0px;
  border-bottom: 1px dashed #ccc;
}
.traffic-card .card-body .growth-rate {
  border-bottom: none;
}

.traffic-card .card-body .card-item .details {
  width: 160px;
}

.traffic-card .card-body .title {
  font-size: 18px;
  color: #3f4047;
  font-weight: 600;
  cursor: pointer;
}

.traffic-card .card-body .title-value {
  font-size: 13px;
  color: #b89fb4;
  font-weight: 500;
  cursor: pointer;
}
.traffic-card .card-body .card-item:nth-child(1) .value span {
  font-size: 20px;
  color: #22b9ff;
  font-weight: 600;
  cursor: pointer;
}
.traffic-card .card-body .card-item:nth-child(2) .value span {
  font-size: 20px;
  color: #f4516c;
  font-weight: 600;
  cursor: pointer;
}
.traffic-card .card-body .card-item:nth-child(3) .value span {
  font-size: 20px;
  color: #34bfac;
  font-weight: 600;
  cursor: pointer;
}
.traffic-card .card-body.leads {
  height: 100%;
}
.traffic-card .card-body .daily-leads {
  padding: 24px 0px;
  height: 25%;
}
.traffic-card .card-body .daily-leads-graph {
  height: 60%;
}

.traffic-card .card-body .daily-leads-graph .graph-img {
  height: 100%;
}
.traffic-card .card-body .daily-leads-graph .graph-img img {
  height: 200px;
  width: 350px;
}
.growth-rate-select {
  font-size: 14px;
  border-radius: 8px;
  padding: 8px 24px;
  border: 1px solid gray;
}
@media (min-width: 991px) {
  .traffic-container {
    padding-left: 116px;
  }
}
@media (max-width: 768px) {
  .traffic-card-box {
    flex-direction: column;
  }
  .traffic-card .card-body .daily-leads-graph .graph-img img {
    height: 140px;
    width: 220px;
  }
  .traffic-card-box .traffic-card {
    border-right: none;
    border-bottom: 1px solid #ebedf2;
  }
}
