@media (min-width: 993px) {
  .m-grid__item {
    flex: 0 0 auto;
  }
  .m-aside-left,
  .m-aside-left {
    top: 100px;
  }
  .m-aside-left {
    bottom: 0;
    position: fixed;
    height: auto !important;
    left: 0;
    z-index: 100;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .m-aside-left {
    width: 100px;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
  }
  .m-aside-left.m-aside-left--skin-light {
    -webkit-box-shadow: 13px -2px 17px -12px rgba(41, 50, 66, 0.05);
    -moz-box-shadow: 13px -2px 17px -12px rgba(41, 50, 66, 0.05);
    box-shadow: 13px -2px 17px -12px rgba(41, 50, 66, 0.05);
  }

  .m-aside-left {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
  }
}
.m-aside-left {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
.m-aside-left.m-aside-left--skin-light {
  background-color: #ffffff;
}
