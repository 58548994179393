.m-subheader-search {
  background: #564fc1;
  padding: 30px 30px;
}
.m-subheader-search .m-subheader-search__title {
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  margin: 0;
}

@media (min-width: 991px) {
  .m-subheader-search {
    margin-left: 100px;
  }
}

@media only screen and (max-width: 768px) {
  .m-subheader-search {
    margin-left: unset;
  }
}
