@media screen and (min-width: 991px) {
  .form-container {
    padding-left: 100px;
    padding-right: 16px;
  }
  .client-form {
    width: 60%;
  }
}
@media screen and (max-width: 768px) {
  .header-info {
    flex-direction: column;
    align-items: flex-start;
  }
}
.header-info {
  padding-left: 16px;
}
.form-container {
  padding-top: 24px;
}

.heading-header {
  font-weight: 600;
  font-size: 20px;
}
.header-icon,
.header-path {
  color: gray;
}
.form-body {
  /* margin-top: 84px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.client-form {
  background-color: white;
  padding: 24px 0px;
  margin: 48px 0px;
}
.form-header {
  width: calc(100% - 36px);
  padding-bottom: 24px;
  padding-left: 36px;
  border-bottom: 1px solid grey;
  margin-bottom: 36px;
}
.form-header h4 {
  width: 100%;
  font-weight: 600;
}
.text-box {
  padding-left: 36px;
  display: flex;
  align-items: center;
  width: calc(100% - 36px);
  gap: 16px;
}
.text-box label {
  width: 30%;
}
.text-box input {
  width: 50%;
  height: 32px;
  border-radius: 4px;
  outline: none;
  border: 1px solid grey;
}
.text-box select {
  width: 50%;
  height: 32px;
  border-radius: 4px;
  outline: none;
  border: 1px solid grey;
}

.text-file {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  width: calc(50% - 4px) !important;
  height: 24px !important;
}
.form-footer {
  width: calc(100% - 72px);
  border-top: 1px solid grey;
  padding: 24px 36px;
}
.form-footer a button {
  color: #ffffff;
  font-weight: 600;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 12px 24px;
  background: #00c5dc;
}

.copyright {
  padding: 24px 16px;
  background-color: white;
  width: calc(100% - 16px);
}
